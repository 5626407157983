<template>
  <v-container
    v-if="!loading"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <h1>{{ userFullName }}</h1>
        <v-card class="mb-2">
          <v-card-text>
            <core-affair-detail-info-two-column
              :label-first-col="$t('User.email')"
              :label-second-col="$t('User.customer')"
            >
              <template v-slot:first-col>
                {{ user ? user.email : '' }}
              </template>
              <template v-slot:second-col>
                {{ user && user.customer ? user.customer.name : '-' }}
              </template>
            </core-affair-detail-info-two-column>
          </v-card-text>
        </v-card>
        <v-form>
          <v-card>
            <v-card-title>
              <h2>{{ $t('User.settings') }}</h2>
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="12"
                  md="4"
                  lg="2"
                  class="font-weight-bold"
                >
                  {{ $t('User.updateFrequency') }}
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  lg="10"
                >
                  <v-select
                    v-model="frequency"
                    :items="items"
                    :label="$t('User.chooseFrequency')"
                    solo
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-center text-md-right"
                >
                  <v-btn
                    tile
                    color="primary"
                    :loading="saving"
                    :disabled="saving"
                    @click="onSaveClick"
                  >
                    {{ $t('User.save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  metaInfo () {
    return {
      title: this.$t('User.pageTitle'),
    }
  },
  data () {
    return {
      saving: false,
      items: [
        {
          text: this.$t('User.frequency.instantly'),
          value: 1,
        },
        {
          text: this.$t('User.frequency.daily'),
          value: 2,
        },
        {
          text: this.$t('User.frequency.weekly'),
          value: 3,
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', [
      'user',
      'loading',
    ]),
    userFullName () {
      return this.user ? `${this.user.name} ${this.user.surname}` : ''
    },
    frequency: {
      get () {
        return this.user ? this.user.interval_id : 0
      },
      set (intervalId) {
        this.user.interval_id = intervalId
      },
    },
  },
  methods: {
    ...mapActions('auth', [
      'updateUser',
    ]),
    async onSaveClick () {
      this.saving = true
      await this.updateUser({ intervalId: this.user.interval_id })
      this.saving = false
    },
  },
}
</script>
